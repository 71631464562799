const ImgIcon = () => {
  return (
    <svg
      width="60%"
      height="60%"
      viewBox="0 0 16 15"
      fill="#929292"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.800551 0H0.8V0.8H0V0.800034H0.8V1.60003H0V12.8H0.8V13.6V13.6V14.4H0V14.4H16V0H15.9994V0.8H15.2003H15.1994H14.4003V0H1.60055V0.8H0.800551V0ZM14.4 1.6V12.8H1.6V1.6H14.4ZM9.6 4.8H8V6.4H6.4V8H4.8V9.6H3.2V11.2H4.8V9.6H6.4V8H8V6.4H9.6V8H11.2V9.6H12.8V8H11.2V6.4H9.6V4.8ZM4.8 3.2H3.2V4.8H4.8V3.2ZM15.1994 13.6V12.8H15.9994V13.6V13.6V14.4H15.2003H15.1994H14.4003V13.6H15.1994ZM1.60055 13.6H0.800551V14.4H1.60055V13.6ZM15.9994 0.800034H15.1994V1.60003H15.9994V0.800034Z"
      ></path>
    </svg>
  );
};

export default ImgIcon;
